@import '@/styles/common.scss';

#parkingDiv {
  width: 80%;
  height: 100%;
}
.parkingCfgRow {
  left: 50px;
  top: 30px;
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.parkingCfgCol {
  height: 100%;
  width: 100%;
}
.bottomDiv {
  left: 70px;
  top: 50px;
  width: 100%;
  height: 15%;
  align-items: center;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}

#container {
  height: 100px;
  overflow-y: scroll;
}
